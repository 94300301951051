import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/layout/MainLayout.vue'
import store from "@/store"

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/point-integration',
        name: 'PointIntegration',
        component: () => import('@/views/PointIntegration.vue'),
      },
      {
        path: '/entry-step1',
        name: 'EntryStep1',
        component: () => import('@/views/entry/Step1.vue'),
      },
      {
        path: '/entry-step2',
        name: 'EntryStep2',
        component: () => import('@/views/entry/Step2.vue'),
      },
      {
        path: '/entry-step3',
        name: 'EntryStep3',
        component: () => import('@/views/entry/Step3.vue'),
      },
      {
        path: '/entry-step4',
        name: 'EntryStep4',
        component: () => import('@/views/entry/Step4.vue'),
      },
      {
        path: '/reset-step1',
        name: 'ResetStep1',
        component: () => import('@/views/reset/Step1.vue'),
      },
      {
        path: '/reset-step2',
        name: 'ResetStep2',
        component: () => import('@/views/reset/Step2.vue'),
      },
      {
        path: '/reset-step3',
        name: 'ResetStep3',
        component: () => import('@/views/reset/Step3.vue'),
      },
      {
        path: '/retry-step1',
        name: 'RetryStep1',
        component: () => import('@/views/retry/Step1.vue'),
      },
      {
        path: '/retry-step2',
        name: 'RetryStep2',
        component: () => import('@/views/retry/Step2.vue'),
      },
      {
        path: '/retry-step3',
        name: 'RetryStep3',
        component: () => import('@/views/retry/Step3.vue'),
      },
      {
        path: '/edit-step1',
        name: 'EditStep1',
        component: () => import('@/views/edit/Step1.vue'),
      },
      {
        path: '/edit-step2',
        name: 'EditStep2',
        component: () => import('@/views/edit/Step2.vue'),
      },
      {
        path: '/edit-step3',
        name: 'EditStep3',
        component: () => import('@/views/edit/Step3.vue'),
      },
      {
        path: '/edit-verify-email',
        name: 'EditVerifyEmail',
        component: () => import('@/views/edit/VerifyEmail.vue'),
      },
      {
        path: '/integration-step1',
        name: 'IntegrationStep1',
        component: () => import('@/views/integration/Step1.vue'),
      },
      {
        path: '/integration-step1/question',
        name: 'IntegrationStep1Question',
        component: () => import('@/views/integration/Step1Question.vue'),
      },
      {
        path: '/integration-step2',
        name: 'IntegrationStep2',
        component: () => import('@/views/integration/Step2.vue'),
      },
      {
        path: '/integration-step2/question',
        name: 'IntegrationStep2Question',
        component: () => import('@/views/integration/Step2Question.vue'),
      },
      {
        path: '/integration-step3',
        name: 'IntegrationStep3',
        component: () => import('@/views/integration/Step3.vue'),
      },
      {
        path: '/integration-step4',
        name: 'IntegrationStep4',
        component: () => import('@/views/integration/Step4.vue'),
      },
      {
        path: '/reset-step1/question',
        name: 'ResetStep1Question',
        component: () => import('@/views/reset/Step1Question.vue'),
      },{
        path: '/reset-step2/question',
        name: 'ResetStep2Question',
        component: () => import('@/views/reset/Step2Question.vue'),
      },
      {
        path:'/yearcup/auth',
        name: 'YearcupAuth',
        component: () => import('@/views/yearcup/Auth.vue')
      },
      {
        path:'/yearcup',
        name: 'YearcupStep1',
        component: () => import('@/views/yearcup/Step1.vue')
      },
      {
        path:'/yearcup/mail-input',
        name: 'YearcupMailInput',
        component: () => import('@/views/yearcup/MailInput.vue')
      },
      {
        path:'/yearcup-pickup-location',
        name: 'YearcupPickupLocation',
        component: () => import('@/views/yearcup/pickupLocation.vue')
      },
      {
        path:'/yearcup/address',
        name: 'YearcupAddress',
        component: () => import('@/views/yearcup/Address.vue')
      },
      {
        path:'/yearcup/confirm-store',
        name: 'YearcupConfirm',
        component: () => import('@/views/yearcup/Confirm.vue')
      },
      {
        path:'/yearcup/confirm-send',
        name: 'YearcupConfirm2',
        component: () => import('@/views/yearcup/Confirm2.vue')
      },
      {
        path:'/yearcup/complete',
        name: 'YearcupComplete',
        component: () => import('@/views/yearcup/Complete.vue')
      },
      {
        path:'/yearcup-edit/auth',
        name: 'YearcupEditAuth',
        component: () => import('@/views/yearcup-edit/Auth.vue')
      },
      {
        path:'/yearcup-edit',
        name: 'YearcupEditStep1',
        component: () => import('@/views/yearcup-edit/Step1.vue')
      },
      {
        path:'/yearcup-edit/status-store',
        name: 'YearcupEditStep2Store',
        component: () => import('@/views/yearcup-edit/Step2Store.vue')
      },
      {
        path:'/yearcup-edit/status-send',
        name: 'YearcupEditStep2Send',
        component: () => import('@/views/yearcup-edit/Step2Send.vue')
      },
      {
        path:'/yearcup-edit/mail-input',
        name: 'YearcupEditMailInput',
        component: () => import('@/views/yearcup-edit/MailInput.vue')
      },
      {
        path:'/yearcup-edit-pickup-location',
        name: 'YearcupEditPickupLocation',
        component: () => import('@/views/yearcup-edit/editpPckupLocation.vue')
      },
      {
        path:'/yearcup-edit/address',
        name: 'YearcupEditAddress',
        component: () => import('@/views/yearcup-edit/Address.vue')
      },
      {
        path:'/yearcup-edit/confirm-store',
        name: 'YearcupEditConfirm',
        component: () => import('@/views/yearcup-edit/Confirm.vue')
      },
      {
        path:'/yearcup-edit/confirm-send',
        name: 'YearcupEditConfirm2',
        component: () => import('@/views/yearcup-edit/Confirm2.vue')
      },
      {
        path:'/yearcup-edit/complete',
        name: 'YearcupEditComplete',
        component: () => import('@/views/yearcup-edit/Complete.vue')
      },
    ],
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { top: 0 }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

router.beforeEach((to, from, next) => {
  store.dispatch('setRedirectTo',{
    redirectTo: to.query.redirect
  }).then(_ => {})
  next()
})

export default router