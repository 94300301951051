import {createStore} from 'vuex'
import cognito from '@/utils/cognito'
import {ref} from "vue"

const store = createStore({
  state: {
    isShowModal: false,
    email: '',
    emailUserName: '',
    emailDomainName: '',
    otherEmailDomain: '',
    password: '',
    passwordConfirm: '',
    birth: {
      year: '',
      month: '',
      day: '',
    },
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    accessToken: '',
    loginId: '',
    userId: '',
    hasPhoneNumber: false,
    unnecessaryAccessToken: '',
    unnecessaryEmail: '',
    unnecessaryAnswer: '',
    unnecessaryQuestion: '',
    integrationMailError: '',
    sourceUser: null,
    targetUser: null,
    isPasswordCertification: false,
    gender: '',
    occupation: '',
    interests: [],
    question1: null,
    question2: null,
    answer1: '',
    answer2: '',
    agreedToTermsOfUse: true,
    yearCupCount: {
      dayId: "",
      daySelect: 0,
      nightId: "",
      nightSelect: 0,
      isPickUp: false
    },
    yearCupAddress: {
      email: '',
      name: '',
      kana: '',
      zipcode: '',
      prefectures: '',
      city: '',
      address1: '',
      address2: '',
      tel: '',
    },
    yearCupPickup: {
      email: '',
      name: '',
      kana: '',
      tel: '',
      shop: {
        code: '',
        name: ''
      }
    },
    yearCupEntryId: '',
    yearCupPeriodId: '',
    yearCupCompleteId: '',
    redirectTo: null,
  },
  getters: {
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    firstNameKana: state => state.firstNameKana,
    lastNameKana: state => state.lastNameKana,
    email: state => state.email,
    emailUserName: state => state.emailUserName,
    emailDomainName: state => state.emailDomainName,
    otherEmailDomain: state => state.otherEmailDomain,
    gender: state => state.gender,
    occupation: state => state.occupation,
    interests: state => state.interests,
    password: state => state.password,
    birth: state => state.birth,
    isShowModal: state => state.isShowModal,
    accessToken: state => state.accessToken,
    loginId: state => state.loginId,
    userId: state => state.userId,
    hasPhoneNumber: state => state.hasPhoneNumber,
    unnecessaryAccessToken: state => state.unnecessaryAccessToken,
    unnecessaryEmail: state => state.unnecessaryEmail,
    unnecessaryQuestion: state => state.unnecessaryQuestion,
    unnecessaryAnswer: state => state.unnecessaryAnswer,
    integrationMailError: state => state.integrationMailError,
    sourceUser: state => state.sourceUser,
    targetUser: state => state.targetUser,
    isPasswordCertification: state => state.isPasswordCertification,
    question1: state => state.question1,
    question2: state => state.question2,
    answer1: state => state.answer1,
    answer2: state => state.answer2,
    agreedToTermsOfUse: state => state.agreedToTermsOfUse,
    yearCupCount: state => state.yearCupCount,
    yearCupAddress: state => state.yearCupAddress,
    yearCupPickup: state => state.yearCupPickup,
    yearCupEntryId: state => state.yearCupEntryId,
    yearCupPeriodId: state => state.yearCupPeriodId,
    yearCupCompleteId: state => state.yearCupCompleteId,
    redirectTo: state => state.redirectTo,
  },
  mutations: {
    SET_FIRST_NAME: (state, firstName) => {
      state.firstName = firstName
    },
    SET_LAST_NAME: (state, lastName) => {
      state.lastName = lastName
    },
    SET_FIRST_NAME_KANA: (state, firstNameKana) => {
      state.firstNameKana = firstNameKana
    },
    SET_LAST_NAME_KANA: (state, lastNameKana) => {
      state.lastNameKana = lastNameKana
    },
    SET_GENDER: (state, gender) => {
      state.gender = gender
    },
    SET_OCCUPATION: (state, occupation) => {
      state.occupation = occupation
    },
    SET_INTERESTS: (state, interests) => {
      state.interests = interests
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_EMAIL_USER: (state, emailUserName) => {
      state.emailUserName = emailUserName
    },
    SET_EMAIL_DOMAIN: (state, emailDomainName) => {
      state.emailDomainName = emailDomainName
    },
    SET_OTHER_EMAIL_DOMAIN: (state, otherEmailDomain) => {
      state.otherEmailDomain = otherEmailDomain
    },
    SET_PASSWORD: (state, password) => {
      state.password = password
    },
    SET_PASSWORD_CONFIRM: (state, passwordConfirm) => {
      state.passwordConfirm = passwordConfirm
    },
    SET_BIRTH: (state, birth) => {
      state.birth = birth
    },
    SET_QUESTION1: (state, question1) => {
      state.question1 = question1
    },
    SET_QUESTION2: (state, question2) => {
      state.question2 = question2
    },
    SET_ANSWER1: (state, answer1) => {
      state.answer1 = answer1
    },
    SET_ANSWER2: (state, answer2) => {
      state.answer2 = answer2
    },
    SET_AGREED_TO_TERMS_OF_USE: (state, agreedToTermsOfUse) => {
      state.agreedToTermsOfUse = agreedToTermsOfUse
    },
    SET_IS_SHOW_MODAL: (state, isShowModal) => {
      state.isShowModal = isShowModal
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
      state.accessToken = accessToken
    },
    SET_UNNECESSARY_ACCESS_TOKEN: (state, accessToken) => {
      state.unnecessaryAccessToken = accessToken
    },
    SET_UNNECESSARY_EMAIL: (state, email) => {
      state.unnecessaryEmail = email
    },
    SET_UNNECESSARY_QUESTION: (state, question) => {
      state.unnecessaryQuestion = question
    },
    SET_UNNECESSARY_ANSWER: (state, answer) => {
      state.unnecessaryAnswer = answer
    },
    SET_INTEGRATION_MAIL_ERROR: (state, integrationMailError) => {
      state.integrationMailError = integrationMailError
    },
    SET_IS_PASSWORD_CERTIFICATION: (state, isPasswordCertification) => {
      state.isPasswordCertification = isPasswordCertification
    },
    SET_LOGIN_ID: (state, loginId) => {
      state.loginId = loginId
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId
    },
    SET_SOURCE_USER: (state, sourceUser) => {
      state.sourceUser = sourceUser
    },
    SET_TARGET_USER: (state, targetUser) => {
      state.targetUser = targetUser
    },
    SET_HAS_PHONE_NUMBER: (state, hasPhoneNumber) => {
      state.hasPhoneNumber = hasPhoneNumber
    },
    SET_YEAR_CUP_COUNT: (state, yearCupCount) => {
      state.yearCupCount = yearCupCount
    },
    SET_YEAR_CUP_ADDRESS: (state, yearCupAddress) => {
      state.yearCupAddress = yearCupAddress
    },
    SET_YEAR_CUP_PICKUP: (state, yearCupPickup) => {
      state.yearCupPickup = yearCupPickup
    },
    SET_YEAR_CUP_ENTRY_ID: (state, yearCupEntryId) => {
      state.yearCupEntryId = yearCupEntryId
    },
    SET_YEAR_CUP_PERIOD_ID: (state, yearCupPeriodId) => {
      state.yearCupPeriodId = yearCupPeriodId
    },
    SET_YEAR_CUP_COMPLETE_ID: (state, yearCupCompleteId) => {
      state.yearCupCompleteId = yearCupCompleteId
    },
    SET_REDIRECT_TO: (state, redirectTo) => {
      state.redirectTo = redirectTo
    }
  },
  actions: {
    openModal({commit}) {
      commit('SET_IS_SHOW_MODAL', true)
    },
    closeModal({commit}) {
      commit('SET_IS_SHOW_MODAL', false)
    },
    login({commit}, {email, password}) {
      commit('SET_EMAIL', email)
      commit('SET_PASSWORD', password)
      return new Promise((resolve, reject) => {
        cognito.authenticateUser(email, password).then(result => {
          commit('SET_ACCESS_TOKEN', result.idToken.jwtToken) // accessToken vs idToken
          resolve(result)
        }).catch(err => reject(err))
      })
    },
    signUpComfirm({commit}, {
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      gender,
      occupation,
      interests,
      email,
      emailUserName,
      emailDomainName,
      otherEmailDomain,
      password,
      birth,
      question1,
      question2,
      answer1,
      answer2
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_FIRST_NAME', firstName)
        commit('SET_LAST_NAME', lastName)
        commit('SET_FIRST_NAME_KANA', firstNameKana)
        commit('SET_LAST_NAME_KANA', lastNameKana)
        commit('SET_EMAIL', email)
        commit('SET_EMAIL_USER', emailUserName)
        commit('SET_EMAIL_DOMAIN', emailDomainName)
        commit('SET_OTHER_EMAIL_DOMAIN', otherEmailDomain)
        commit('SET_PASSWORD', password)
        commit('SET_BIRTH', birth)
        commit('SET_GENDER', gender)
        commit('SET_OCCUPATION', occupation)
        commit('SET_INTERESTS', interests)
        commit('SET_QUESTION1', question1)
        commit('SET_QUESTION2', question2)
        commit('SET_ANSWER1', answer1)
        commit('SET_ANSWER2', answer2)
        resolve()
      })
    },
    signUp(_, {name, email, password, birthday}) {
      return cognito.signUp(name, email, password, birthday)
    },
    confirmRegistration(_, {email, code}) {
      return cognito.confirmRegistration(email, code)
    },
    forgetPassword({commit}, email) {
      commit('SET_EMAIL', email)
      return cognito.forgetPassword(email)
    },
    confirmPassword(_, {email, password, code}) {
      return cognito.confirmPassword(email, password, code)
    },
    resendConfirmationCode({commit}, email) {
      commit('SET_EMAIL', email)
      return cognito.resendConfirmationCode(email)
    },
    updateAttributes(_, attributes) {
      return cognito.updateAttributes(attributes)
    },
    updateEmailUserName({ commit }, emailUserName) {
      commit('SET_EMAIL_USER', emailUserName)
    },
    updateEmailDomainName({ commit }, emailDomainName) {
      commit('SET_EMAIL_DOMAIN', emailDomainName)
    },
    updateEmailDomainName({ commit }, otherEmailDomain) {
      commit('SET_OTHER_EMAIL_DOMAIN', otherEmailDomain)
    },
    confirmUpdate(_, {hasPhoneNumber, code}) {
      return cognito.confirmUpdate(hasPhoneNumber, code)
    },
    setHasPhoneNumber({commit}, {hasPhoneNumber}) {
      return new Promise((resolve, reject) => {
        commit('SET_HAS_PHONE_NUMBER', hasPhoneNumber)
        resolve()
      })
    },
    setPointIntegrationUser({commit}, {sourceUser, targetUser}) {
      return new Promise((resolve, reject) => {
        commit('SET_SOURCE_USER', sourceUser)
        commit('SET_TARGET_USER', targetUser)
        resolve()
      })
    },
    setIsPasswordCertification({commit}, {isPasswordCertification}) {
      return new Promise((resolve, reject) => {
        commit('SET_IS_PASSWORD_CERTIFICATION', isPasswordCertification)
        resolve()
      })
    },
    loginToForm({commit}, {loginId}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGIN_ID', loginId)
        resolve()
      })
    },
    signInToForm({commit}, {userId}) {
      return new Promise((resolve, reject) => {
        commit('SET_USER_ID', userId)
        resolve()
      })
    },
    integrationQuestionAnswer({commit}, {email, question, answer}) {
      return new Promise((resolve, reject) => {
        commit('SET_INTEGRATION_MAIL_ERROR', '')
        commit('SET_UNNECESSARY_EMAIL', email)
        commit('SET_UNNECESSARY_QUESTION', question)
        commit('SET_UNNECESSARY_ANSWER', answer)
        resolve()
      })
    },
    integrationMailError({commit}, {error}) {
      return new Promise((resolve, reject) => {
        commit('SET_INTEGRATION_MAIL_ERROR', error)
        resolve()
      })
    },
    integrationLogin({commit}, {email, password}) {
      return new Promise((resolve, reject) => {
        cognito.authenticateUser(email, password).then(result => {
          commit('SET_UNNECESSARY_ACCESS_TOKEN', result.idToken.jwtToken)
          commit('SET_UNNECESSARY_EMAIL', email)
          resolve(result)
        }).catch(err => reject(err))
      })
    },
    setAttribute({commit}, {
      firstName,
      lastName,
      firstNameKana,
      lastNameKana,
      gender,
      occupation,
      interests,
      email,
      emailUserName,
      emailDomainName,
      otherEmailDomain,
      birth,
    }) {
      return new Promise((resolve, reject) => {
        commit('SET_FIRST_NAME', firstName)
        commit('SET_LAST_NAME', lastName)
        commit('SET_FIRST_NAME_KANA', firstNameKana)
        commit('SET_LAST_NAME_KANA', lastNameKana)
        commit('SET_EMAIL', email)
        commit('SET_EMAIL_USER', emailUserName)
        commit('SET_EMAIL_DOMAIN', emailDomainName)
        commit('SET_OTHER_EMAIL_DOMAIN', otherEmailDomain)
        commit('SET_BIRTH', birth)
        commit('SET_GENDER', gender)
        commit('SET_OCCUPATION', occupation)
        commit('SET_INTERESTS', interests)
        resolve()
      })
    },
    setCupSelect({commit}, {yearCupCount}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COUNT', yearCupCount)
        resolve()
      })
    },
    setYearCupAddress({commit}, {yearCupAddress}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_ADDRESS', yearCupAddress)
        resolve()
      })
    },
    setYearCupPickUp({commit}, {yearCupPickup}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_PICKUP', yearCupPickup)
        resolve()
      })
    },
    setYearCupEntryId({commit}, {yearCupEntryId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_ENTRY_ID', yearCupEntryId)
        resolve()
      })
    },
    setYearCupPeriodId({commit}, {yearCupPeriodId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_PERIOD_ID', yearCupPeriodId)
        resolve()
      })
    },
    setYearCupCompleteId({commit}, {yearCupCompleteId}) {
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COMPLETE_ID', yearCupCompleteId)
        resolve()
      })
    },
    deleteYearCupSelect({commit}) {
      const initYearCupCount = {
        dayId: "",
        daySelect: 0,
        nightId: "",
        nightSelect: 0,
        isPickUp: false
      }
      return new Promise((resolve, reject) => {
        commit('SET_YEAR_CUP_COUNT', initYearCupCount)
        resolve()
      })
    },
    deleteYearCupPickUpData({commit}) {
      const initPickUpData = {
        email: '',
        name: '',
        kana: '',
        tel: '',
        shop: {
          code: '',
          name: ''
        }
      }
      commit('SET_YEAR_CUP_PICKUP', initPickUpData)
    },
    deleteYearCupAddress({commit}) {
      const initYearCupAddress = {
        email: '',
        name: '',
        kana: '',
        zipcode: '',
        prefectures: '',
        city: '',
        address1: '',
        address2: '',
        tel: '',
      }
      commit('SET_YEAR_CUP_ADDRESS', initYearCupAddress)
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_ACCESS_TOKEN', "")
        resolve()
      })
    },
    setRedirectTo({commit}, {redirectTo}) {
      return new Promise((resolve, reject) => {
        commit('SET_REDIRECT_TO', redirectTo)
        resolve()
      })
    },
  },
  modules: {},
})

export default store
